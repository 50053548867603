/* https://developers.google.com/web/updates/2013/12/300ms-tap-delay-gone-away */
html {
  touch-action: manipulation;
}

input[type=text],input[type=password], input[type=tel], input[type=email], input[type=text][disabled] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

textarea, input, button {
  outline: none;
}