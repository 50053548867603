.tooltipster-sidetip .tooltipster-box {
    border: none;
    background: #1b1b1b;
    background: rgba(10, 10, 10, .9)
}
.tooltipster-sidetiptooltipster-bottom .tooltipster-box {
    margin-top: 8px
}
.tooltipster-sidetip.tooltipster-left .tooltipster-box {
    margin-right: 8px
}
.tooltipster-sidetip.tooltipster-right .tooltipster-box {
    margin-left: 8px
}
.tooltipster-sidetip.tooltipster-top .tooltipster-box {
    margin-bottom: 8px
}
.tooltipster-sidetip .tooltipster-arrow {
    height: 8px;
    margin-left: -8px;
    width: 16px
}
.tooltipster-sidetip.tooltipster-left .tooltipster-arrow,
.tooltipster-sidetip.tooltipster-right .tooltipster-arrow {
    height: 16px;
    margin-left: 0;
    margin-top: -8px;
    width: 8px
}
.tooltipster-sidetip .tooltipster-arrow-background {
    display: none
}
.tooltipster-sidetip .tooltipster-arrow-border {
    border: 8px solid transparent
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
    border-bottom-color: #1b1b1b;
    border-bottom-color: rgba(10, 10, 10, .9)
}
.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border {
    border-left-color: #1b1b1b;
    border-left-color: rgba(10, 10, 10, .9)
}
.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border {
    border-right-color: #1b1b1b;
    border-right-color: rgba(10, 10, 10, .9)
}
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
    border-top-color: #1b1b1b;
    border-top-color: rgba(10, 10, 10, .9)
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-uncropped {
    top: -8px
}
.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-uncropped {
    left: -8px
}

/* Green success theme */
.tooltipster-greenSuccess .tooltipster-box {
  border: 0px solid #46C874;
  background: #46C874;
  box-shadow: black 0px 2px 14px -6px;
}

/* Red error theme */
.tooltipster-redError .tooltipster-box {
  background: #E95B5B;
  border: 0px solid #E95B5B;
}

.tooltipster-sidetip.tooltipster-redError.tooltipster-bottom .tooltipster-arrow-border {
    border-bottom-color: #E95B5B;
}
.tooltipster-sidetip.tooltipster-redError.tooltipster-left .tooltipster-arrow-border {
    border-left-color: #E95B5B;
}
.tooltipster-sidetip.tooltipster-redError.tooltipster-right .tooltipster-arrow-border {
    border-right-color: #E95B5B;
}
.tooltipster-sidetip.tooltipster-redError.tooltipster-top .tooltipster-arrow-border {
    border-top-color: #E95B5B;
}



/* Blue info bubble theme */
.tooltipster-blueInfo {
  border-radius: 3px; 
  border: 0px solid #2B82D9;
  background: #2B82D9;
  color: #fff;
  box-shadow: black 0px 2px 14px -6px;
}

.tooltipster-blueInfo .tooltipster-content {
  font-size: 16px;
  line-height: 20px;
  padding: 14px 18px 15px 16px;
  overflow: hidden;
}

/* Blue promo ribbon bubble theme */
.tooltipster-bluePromoRibbon {
  border-radius: 3px; 
  border: 0px solid #2B82D9;
  background: #2B82D9;
  color: #fff;
  box-shadow: black 0px 2px 14px -6px;
}

.tooltipster-bluePromoRibbon .tooltipster-content {
  font-size: 16px;
  line-height: 20px;
  padding: 14px 18px 15px 16px;
  overflow: visible !important;
}

.tooltipster-sidetip .tooltipster-content {
  color: #fff;
  line-height: 1.25em;
  padding: 0.75em 1.125em;
}
