/* Add font awesome rendering sharpness
*/
.fontawesome, [font-smoothing="antialiased"], .font-icon {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

[truncate=true] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

[truncate="false"] {
  white-space: normal;
}

[cursor="resize"] {
  cursor: ew-resize;
}

[cursor="handle"] {
  cursor: ew-resize;
}

[resize="vertical"] {
  resize: vertical;
}

[noselect='true'], [user-select="none"], [not-selectable="true"] {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

[user-select="all"] {
    -webkit-user-select: all;
    -khtml-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    user-select: all;
}

[disable-native-tap-highlight="true"] {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

@keyframes yellowHighlight {
  0% {
    background: #ffffdd;
  }
  100% {
    background: #fdfdfd;
  }
}

.yellow-highlight, [yellow-highlight] {
  animation: yellowHighlight 6s ease;
}

.is-grayed-out {
  pointer-events: none;
}

.is-grayed-out[tooltipster], .is-grayed-out[simply-tooltip] {
  pointer-events: auto;
}

.is-grayed-out[tooltipster]:active, .is-grayed-out[simply-tooltip]:active {
  pointer-events: none;
}

[pointer-events="none"] {
  pointer-events: none;
}

.bem-button-with-spinner.is-inactive-with-preloader {
  pointer-events: none;
}

[webkit-touch-callout="none"] {
  -webkit-touch-callout: none;
}

[webkit-touch-callout="default"] {
  -webkit-touch-callout: default!important;
}

[webkit-overflow-scrolling="touch"] {
  -webkit-overflow-scrolling: touch;
}

.w-webflow-badge {
	display: none!important;
}

.initial-load-overlay, [initial-load-overlay] {
  display: block;
}

[animation-spin="true"], .bem-spinner {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 2000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 2000;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 2000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

[will-change="transform"] {
  will-change: transform;
}

[will-change="opacity"] {
  will-change: opacity;
}

[word-break="break-all"] {
  word-break: break-all;
}

[simply-input-type] {
  text-transform: uppercase;
}

[simply-input-type="date"] {
  padding-right: 0;
}

[simply-input-type="date"]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  opacity: 0;
  width: 40px;
  height: 100%;
  position: absolute;
  right: 0px;
  z-index: 2;
}

[simply-input-type="date"][type=date]::-webkit-inner-spin-button, 
[simply-input-type="date"][type=date]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
  padding: 0;
  display: none;
}

@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

[animation-horizontal-background-position] {
  -webkit-animation: horizontalBackgroundPosition 1400ms linear infinite;
  -moz-animation: horizontalBackgroundPosition 1400ms linear infinite;
  animation: horizontalBackgroundPosition 1400ms linear infinite;
  background-size: 50% 100%;
}

@-webkit-keyframes horizontalBackgroundPosition {
  0%{background-position:-100% 50%}
  100%{background-position:100% 50%}
}
@-moz-keyframes horizontalBackgroundPosition {
  0%{background-position:-100% 50%}
  100%{background-position:100% 50%}
}
@keyframes horizontalBackgroundPosition {
  0%{background-position:-100% 50%}
  100%{background-position:100% 50%}
}

[animation-vertical-background-position] {
  -webkit-animation: verticalBackgroundPosition 1400ms linear infinite;
  -moz-animation: verticalBackgroundPosition 1400ms linear infinite;
  animation: verticalBackgroundPosition 1400ms linear infinite;
  background-size: 100% 200%;
}

[animation-vertical-background-position-fast] {
  -webkit-animation: verticalBackgroundPosition 700ms linear infinite;
  -moz-animation: verticalBackgroundPosition 700ms linear infinite;
  animation: verticalBackgroundPosition 700ms linear infinite;
  background-size: 100% 200%;
}

[is-hidden-by-search] {
  display: none;
}

@-webkit-keyframes verticalBackgroundPosition {
  0%{background-position:50% 100%}
  100%{background-position:50% -100%}
}
@-moz-keyframes verticalBackgroundPosition {
  0%{background-position:50% 100%}
  100%{background-position:50% -100%}
}
@keyframes verticalBackgroundPosition {
  0%{background-position:50% 100%}
  100%{background-position:50% -100%}
}

[font-variant-ligatures="none"] {
  font-variant-ligatures: none;
}

/* https://stackoverflow.com/questions/52508598/html5-video-element-on-iphone-has-border */
[simply-webflow-video-player] video {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}